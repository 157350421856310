const Items = {
  item: [
    {
      id: 0,
      category: 1,
      title: "شاورما صغير",
      description: "شاورما لذيذة بحجم صغير ومثالية للوجبات السريعة",
      price: "6",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 1,
      category: 1,
      title: "صاروخ شاورما",
      description: "صاروخ شاورما مليء بالنكهات الشهية",
      price: "13",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 2,
      category: 1,
      title: "جامبو شاورما صامولي",
      description: "شاورما صامولي بالحجم الكبير، مليئة باللحم الشهي",
      price: "13",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 3,
      category: 1,
      title: "شاورما عربي",
      description: "شاورما مقطعة إلى قطع صغيرة تقدم مع خبز عربي",
      price: "19",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 4,
      category: 1,
      title: "شاورما عربي دبل",
      description: "شاورما عربي مع ضعف الكمية لمحبي الطعم المميز",
      price: "35",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 5,
      category: 1,
      title: "صحن شاورما صغير",
      description: "صحن شاورما صغير يقدم مع صوصات متنوعة",
      price: "19",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 6,
      category: 1,
      title: "صحن شاورما كبير",
      description: "صحن شاورما كبير يكفي لمشاركة العائلة",
      price: "38",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 7,
      category: 2,
      title: "بروستد دجاج عادي",
      description: "قطع دجاج مقلية بالخلطة السرية مقرمشة وشهية",
      price: "20",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 8,
      category: 2,
      title: "بروستد حراق",
      description: "بروستد دجاج بتتبيلة حارة لعشاق النكهات الجريئة",
      price: "20",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 9,
      category: 2,
      title: "كرسبي دجاج عادي",
      description: "كرسبي دجاج مغطى بطبقة مقرمشة لذيذة",
      price: "20",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 10,
      category: 2,
      title: "كرسبي دجاج حراق",
      description: "كرسبي دجاج بتوابل حارة ونكهة مميزة",
      price: "20",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 11,
      category: 2,
      title: "سمك فيليه قلي حراق",
      description: "سمك فيليه مقلي بتتبيلة حارة لمحبي الطعم القوي",
      price: "25",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 12,
      category: 2,
      title: "سمك فيليه قلي عادي",
      description: "سمك فيليه مقلي ومقرمش بالنكهة الكلاسيكية",
      price: "25",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 13,
      category: 2,
      title: "سمك فيليه مشوي",
      description: "سمك فيليه مشوي على الفحم للحصول على نكهة طبيعية",
      price: "26",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 14,
      category: 2,
      title: "سمك فيليه مشوي مع رز",
      description: "سمك فيليه مشوي يقدم مع أرز مطهو بخبرة",
      price: "30",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 15,
      category: 2,
      title: "سمك فيليه مقلي مع رز",
      description: "وجبة سمك فيليه مقلي تقدم مع أرز شهي",
      price: "30",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 16,
      category: 3,
      title: "شيش طاووق",
      description: "قطع دجاج متبلة مشوية على الفحم",
      price: "9",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 17,
      category: 3,
      title: "كباب دجاج",
      description: "كباب دجاج متبل مشوي على الفحم",
      price: "9",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 18,
      category: 3,
      title: "كباب لحم",
      description: "كباب لحم طري ومتبل بنكهة شرقية",
      price: "9",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 19,
      category: 3,
      title: "كرسبي دجاج",
      description: "دجاج كرسبي مقلي بطريقة احترافية",
      price: "9",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 20,
      category: 4,
      title: "بيتزا خضار",
      description: "بيتزا محشوة بالخضروات الطازجة والجبن الذائب",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 21,
      category: 4,
      title: "بيتزا مارغريتا",
      description: "بيتزا كلاسيكية مع جبن الموزاريلا والريحان الطازج",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 22,
      category: 4,
      title: "بيتزا نقانق",
      description: "بيتزا مغطاة بشرائح النقانق والجبن الذائب",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 23,
      category: 4,
      title: "بيتزا دجاج",
      description: "بيتزا محشوة بقطع دجاج مشوية مع صوص لذيذ",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 24,
      category: 4,
      title: "بيتزا لحم",
      description: "بيتزا باللحم المفروم المتبل ومغطاة بالجبن",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 25,
      category: 4,
      title: "بيتزا شاورما",
      description: "بيتزا مميزة مع شرائح شاورما وصوصات متنوعة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 26,
      category: 4,
      title: "بيتزا مشكل لحوم",
      description: "بيتزا تحتوي على خليط من اللحوم المتنوعة والجبن",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 27,
      category: 4,
      title: "عش بلبل",
      description: "عجينة هشة محشوة باللحم أو الجبن لتجربة شرقية",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 28,
      category: 5,
      title: "برجر لحم",
      description: "برجر كلاسيكي مصنوع من لحم بقر طازج",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 29,
      category: 5,
      title: "برجر دجاج",
      description: "برجر دجاج مشوي ومليء بالنكهات الشهية",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 30,
      category: 5,
      title: "ناجيت دجاج",
      description: "قطع دجاج مقرمشة مناسبة للأطفال والكبار",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 31,
      category: 5,
      title: "بطاطس صغير",
      description: "بطاطس مقلية ومقرمشة بحجم صغير",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 32,
      category: 5,
      title: "بطاطس كبير",
      description: "بطاطس مقلية بحجم كبير لتشاركها مع العائلة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 33,
      category: 5,
      title: "بطاطس بالجبن",
      description: "بطاطس مقلية مغطاة بطبقة لذيذة من الجبن الذائب",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 34,
      category: 5,
      title: "اضافة جبن",
      description: "إضافة جبن على الوجبة لتعزيز النكهة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 35,
      category: 5,
      title: "برجر شاورما",
      description: "برجر مميز بحشوة شاورما لذيذة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 36,
      category: 6,
      title: "مشكل مشوي",
      description: "مجموعة متنوعة من اللحوم المشوية على الفحم",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 37,
      category: 6,
      title: "سمك فيليه مشوي",
      description: "سمك فيليه مشوي بطريقة مثالية مع توابل لذيذة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 38,
      category: 6,
      title: "سمك فيليه مشوي مع رز",
      description: "سمك فيليه مشوي يقدم مع طبق أرز لذيذ",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 39,
      category: 6,
      title: "نص دجاج فحم مع رز",
      description: "نصف دجاجة مشوية على الفحم تقدم مع أرز",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 40,
      category: 6,
      title: "صحن رز",
      description: "طبق أرز مناسب لإضافة النكهة للوجبة الرئيسية",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },

    {
      id: 41,
      category: 0,
      title: "حمص",
      description: "طبق الحمص الكريمي مع زيت الزيتون والتوابل الطازجة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 42,
      category: 0,
      title: "فتوش",
      description: "سلطة فتوش طازجة مع الخضروات المقرمشة وقطع الخبز المحمص",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 43,
      category: 0,
      title: "سلطة خضراء",
      description: "سلطة خضراء مكونة من مزيج من الخضروات الطازجة مع صلصة منعشة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),    },
    {
      id: 44,
      category: 0,
      title: "تبولة",
      description: "سلطة تبولة شهية تحتوي على البرغل والبقدونس والطماطم",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.04 PM.jpeg"),    },
    {
      id: 45,
      category: 0,
      title: "حمص بالشاورما",
      description: "طبق حمص مع إضافة شرائح شاورما لذيذة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 46,
      category: 0,
      title: "ثوم",
      description: "صلصة الثوم الكريمية المميزة المناسبة لجميع الأطباق",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 47,
      category: 0,
      title: "مشكل مقبلات",
      description: "تشكيلة متنوعة من المقبلات الشرقية اللذيذة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 48,
      category: 7,
      title: "زعتر",
      description: "فطيرة الزعتر المحضرة بالعجين الطازج وزيت الزيتون",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 49,
      category: 7,
      title: "محمرة",
      description: "فطيرة المحمرة الشهية المصنوعة من عجين طازج ودبس الفلفل",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 50,
      category: 7,
      title: "جبن سائل",
      description: "فطيرة مغطاة بطبقة لذيذة من الجبن السائل",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 51,
      category: 7,
      title: "جبن عكاوي",
      description: "فطيرة محشوة بجبن عكاوي الطازج والمميز",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 52,
      category: 7,
      title: "سبانخ",
      description: "فطيرة السبانخ المميزة المحضرة من السبانخ الطازج",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 53,
      category: 7,
      title: "لبنة",
      description: "فطيرة محشوة بطبقة من اللبنة الكريمية",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 54,
      category: 7,
      title: "محمرة بالجبن",
      description: "فطيرة تجمع بين طعم المحمرة الغني والجبن الذائب",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 55,
      category: 7,
      title: "لبنة بالعسل",
      description: "فطيرة لبنة تقدم مع طبقة عسل حلوة ولذيذة",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 56,
      category: 7,
      title: "لبنة بالزعتر",
      description: "فطيرة لبنة مغطاة بالزعتر الطازج وزيت الزيتون",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 57,
      category: 7,
      title: "لبنة بالزيتون",
      description: "فطيرة لبنة مغطاة بزيتون طازج ومذاق شهي",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 58,
      category: 7,
      title: "جبن بالبيض",
      description: "فطيرة الجبن مع البيض للحصول على طعم غني ولذيذ",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 59,
      category: 7,
      title: "فطيرة لحم",
      description: "فطيرة محشوة باللحم المفروم المتبل بشكل مثالي",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
    {
      id: 60,
      category: 7,
      title: "فطيرة دجاج",
      description: "فطيرة لذيذة محشوة بقطع الدجاج الطازج",
      price: "12",
      image: require("./images/asset/WhatsApp Image 2024-11-25 at 8.56.05 PM.jpeg"),
    },
  ],
};
export default Items;
