import Merchant from "./merchant";
import Admin from "./admin";
import Visitor from "./visitor";
import Visitormenu from "./visitor/aliwan/src/index";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  // Extract subdomain from hostname
  const subdomain = window.location.hostname.split(".")[0];

  // If the subdomain is "aliwan", render Visitormenu
  if (subdomain === "aliwan") {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Visitormenu />} />
        </Routes>
      </Router>
    );
  }

  // Default case for other subdomains or no subdomain
  return (
    <Router>
      <Routes>
        <Route path="Merchant/*" element={<Merchant />} />
        <Route path="Admin" element={<Admin />} />
        <Route path="/" element={<Visitor />} />
        <Route path="menu/*" element={<Visitormenu />} />
      </Routes>
    </Router>
  );
}

export default App;
